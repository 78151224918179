import { render, staticRenderFns } from "./PageAvailableVariables.vue?vue&type=template&id=0cfb185c&"
import script from "./PageAvailableVariables.vue?vue&type=script&lang=ts&"
export * from "./PageAvailableVariables.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports