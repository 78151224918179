import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('template-import-dialog',{on:{"onSuccess":_vm.reloadCollection},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var isOpen = ref.isOpen;
return [_c('div',{staticClass:"d-flex justify-end flex-gap-16"},[(_vm.canUpdate && _vm.canCreate)?_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","disabled":isOpen}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Import'))+" ")]):_vm._e(),(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'template-create' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Create')))])]):_vm._e()],1)]}}])})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('template-export-dialog',{attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","fab":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("download")])],1)]}}],null,true)})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'template-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('template-action-delete',{attrs:{"value":item,"icon":""},on:{"onSuccess":_vm.onDeleteSuccess}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Templates') },false))}
var staticRenderFns = []

export { render, staticRenderFns }