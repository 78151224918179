import type { TranslateResult } from 'vue-i18n'

/** @deprecated use RuleDefinitions and composable/validator/rules instead **/
export type ValidationRule = boolean | TranslateResult

/** @deprecated use composable/validator/rules instead **/
export const useLocaleValidationRule = (value: string = ''): ValidationRule => {
  if (!value || !value.match(/^[a-z]{2}-[A-Z]{2}$/)) {
    return 'Invalid locale format. Valid format: `xx-XX`'
  }
  return true
}

/** @deprecated use composable/validator/rules instead **/
export const useNotWhitespaceValidationRule = (value: string = ''): ValidationRule => {
  if (value && value.match(/\s/g)) {
    return 'No whitespaces allowed'
  }
  return true
}

/** @deprecated use composable/validator/rules instead **/
export const useNotEmptyValidationRule = (value: any): ValidationRule => {
  return !value ? 'Field cannot be empty' : true
}

/** @deprecated use composable/validator/rules instead **/
export const usePasswordRule = (value: any): ValidationRule => {
  return String(value).length > 7 || 'Password must have min 8 characters'
}

/** @deprecated use composable/validator/rules instead **/
export const useUniqueAttributeValidationRule = (
  value: string,
  list: string[],
  attributeName: string
): ValidationRule => {
  return list.includes(value) ? `This ${attributeName} already exists` : true
}

/** @deprecated use composable/validator/rules instead **/
export const useMatchRangeValidationRule = (value: number, min: number, max: number): ValidationRule => {
  return !(value >= min && value <= max) ? `Value must be between ${min} and ${max}` : true
}

export const useNotEmptyArrayValidationRule = (value: any[]): ValidationRule => {
  return value.length === 0 ? 'List cannot be empty' : true
}

/** @deprecated use composable/validator/rules instead **/
export const useEmailValidationRule = (value: string): ValidationRule => {
  if (!value.match(/\S+@\S+\.\S+/)) {
    return 'Invalid email format'
  }
  return true
}
