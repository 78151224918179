import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form.length)?_c('div',{staticClass:"d-flex flex-column"},[_c(VRow,{staticClass:"my-2 mr-1",attrs:{"justify":"end","align":"end"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.saveAll}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1),_c('translation-tabs',{staticClass:"mb-10",attrs:{"translatable-values":_vm.translatableValues},scopedSlots:_vm._u([{key:"default.value",fn:function(){return [(_vm.form)?_c('breakpoint-tabs',{staticClass:"mb-10",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VExpansionPanels,_vm._l((_vm.form),function(list){return _c(VExpansionPanel,{key:list.code},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(list.title)+" ")]),_c(VExpansionPanelContent,[(list.settings.length)?_c('div',{staticClass:"mt-2"},[_vm._l((list.settings),function(setting){return [_c('setting-inputs',{key:setting.id,attrs:{"value":setting},on:{"change":function($event){return _vm.changed(setting)}}})]})],2):_vm._e()])],1)}),1)]},proxy:true},_vm._l((['xs', 'sm', 'md', 'lg', 'xl']),function(slotName,index){return {key:slotName,fn:function(){return [_c(VExpansionPanels,{key:(slotName + "-" + index)},_vm._l((_vm.form),function(list){return _c(VExpansionPanel,{key:("breakpoints-" + (list.code))},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(list.title)+" ")]),_c(VExpansionPanelContent,[(list.settings.length)?_c('div',{staticClass:"mt-4"},[_vm._l((list.settings),function(setting){return [_c('breakpoint-inputs',{key:("" + (setting.id) + slotName),attrs:{"value":setting,"breakpoint":slotName},on:{"change":function($event){return _vm.changed(setting)}}})]})],2):_vm._e()])],1)}),1)]},proxy:true}})],null,true),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()]},proxy:true},{key:"value",fn:function(ref){
var code = ref.code;
return [_c(VExpansionPanels,_vm._l((_vm.form),function(list,listKey){return _c(VExpansionPanel,{key:list.code},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(list.title)+" ")]),_c(VExpansionPanelContent,[(list.settings.length)?_c('div',{staticClass:"mt-2"},[_vm._l((list.settings),function(setting,settingKey){return [_c('setting-translation-inputs',{key:setting.code,attrs:{"input-key":setting.id,"input-class":{ 'mb-7': setting.description },"setting":setting,"value":_vm.translations(listKey, settingKey, code, 'value').get()},on:{"input":function($event){_vm.translations(listKey, settingKey, code, 'value').set($event)}}})]})],2):_vm._e()])],1)}),1)]}}],null,false,2716400275)})],1):_c('p',{staticClass:"text--disabled text-center"},[_vm._v(" "+_vm._s(_vm.$t('No data available'))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }