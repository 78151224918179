import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-end flex-gap-16 align-center"},[_c('cms-template-select',{attrs:{"dense":"","value":_vm.currentTemplate},on:{"input":_vm.onTemplateSelect}}),(_vm.canCreate && _vm.currentTemplate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'page-create', params: { template: _vm.currentTemplate } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add new page')))])]):_vm._e()],1)]},proxy:true},{key:"scrollable.content.top",fn:function(){return [_c('div',{staticClass:"custom_search"},[_c(VTextField,{attrs:{"placeholder":"Page name search"},on:{"input":_vm.search},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'page-update', params: { template: _vm.currentTemplate, id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.protected",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.protected ? 'done' : 'close'))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"ma-2 text-uppercase"},[_vm._v(" "+_vm._s(item.type.replace('_', ' '))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'page-update', params: { template: _vm.currentTemplate, id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(_vm.canDelete && !item.protected)?_c('template-page-action-delete',{attrs:{"code":_vm.currentTemplate,"value":item,"icon":""},on:{"onSuccess":_vm.onDeleteSuccess}}):_vm._e()]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Template Pages') },false))}
var staticRenderFns = []

export { render, staticRenderFns }