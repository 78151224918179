import { ref } from '@vue/composition-api'
import type { Template } from '@futureecom/futureecom-js/dist/services/cms-service'

const tenancyTemplates = ref<Record<string, { templates?: Template[]; active?: string }>>({})

export const useTemplates = (organisation: string, store: string) => {
  const getTenancyTemplates = (): Template[] | undefined => {
    return tenancyTemplates.value[`${organisation}-${store}`]?.templates
  }

  const setTenancyTemplates = (templates?: Template[]) => {
    tenancyTemplates.value = { ...tenancyTemplates.value, [`${organisation}-${store}`]: { templates } }
  }

  const setActiveTemplateCode = (code: string) => {
    const templates = getTenancyTemplates()
    tenancyTemplates.value = { ...tenancyTemplates.value, [`${organisation}-${store}`]: { templates, active: code } }
  }

  const getActiveTemplateCode = (): string => {
    return tenancyTemplates.value[`${organisation}-${store}`]?.active || ''
  }

  const findTemplateByCode = (code: string): Template | undefined => {
    return (getTenancyTemplates() || []).find((item) => item.code === code)
  }

  return {
    getTenancyTemplates,
    setTenancyTemplates,
    setActiveTemplateCode,
    getActiveTemplateCode,
    findTemplateByCode
  }
}
