import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"messages":_vm.messages || '',"prepend-icon":"mdi-palette","outlined":"","readonly":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},'v-text-field',attrs,false),on))]}}])},[_c(VColorPicker,{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }