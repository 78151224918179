import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,{"on":on,"attrs":attrs,"isOpen":_vm.showDialog})]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('form-validator-wrapper',{on:{"submit":_vm.importHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Import template(s)')))]),_c(VIcon,{attrs:{"size":"32"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,{key:_vm.showDialog},[_c('cms-import-inputs',{staticClass:"d-flex flex-column flex-gap-16",model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end pa-4"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading,"disabled":!valid || _vm.loading,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Import'))+" "),(!_vm.data.parts.length)?_c('span',[_vm._v("("+_vm._s(_vm.$t('all'))+")")]):_vm._e()])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }